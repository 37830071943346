import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  AssumptionsAgreement,
  useAssumptionsAgreement,
} from 'state/formData/assumptionsAgreement';
import useAssumptions from './assumptions';
import {
  AggregatorList,
  AggregatorListItem,
  AssumptionsQuestionField,
  DividerWithMargins,
  EditDetails,
  StyledInfoPanel,
  StyledSectionHeading,
  Subheading,
} from './styles';
import {
  EditButtonIcon,
  EditButtonLink,
  EditSpan,
} from '../DetailsSection/DetailGroupSection/styles';

type AggregatorAssumptionsSectionProps = {
  sectionHeadingId: string;
  getError: FieldFunction<AssumptionsAgreement, string | undefined>;
  showValidation: FieldFunction<AssumptionsAgreement, void>;
};

type CsAggregators = {
  csPetAggregatorsV2: {
    assumptions: {
      section_heading: string;
      subheading: string;
      please_edit_details_text: string;
      confirmation_text: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    eligibility_panel: {
      dog_text: string;
      cat_text: string;
      cat_and_dog_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetAggregatorsV2 {
      assumptions {
        section_heading
        subheading
        please_edit_details_text
        confirmation_text
        edit_button_text
        edit_button_screenreader_text
      }
      eligibility_panel {
        dog_text
        cat_text
        cat_and_dog_text
      }
    }
  }
`;

const AggregatorAssumptionsSection: React.FC<AggregatorAssumptionsSectionProps> = ({
  sectionHeadingId,
  getError,
  showValidation,
}) => {
  const {
    csPetAggregatorsV2: {
      assumptions: {
        section_heading,
        subheading,
        confirmation_text,
        edit_button_text,
        edit_button_screenreader_text,
      },
      eligibility_panel: { dog_text, cat_text, cat_and_dog_text },
    },
  } = useStaticQuery<CsAggregators>(query);

  const assumptions = useAssumptions();
  const [assumptionsAgreement, updateAssumptionsAgreement] = useAssumptionsAgreement();
  const currentQuote = useCurrentQuote();

  /* istanbul ignore if */
  if (!assumptions) {
    return null;
  }

  const petNames = currentQuote?.petInfos?.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const getEligibilityInfoText = (): string => {
    if (currentQuote.petInfos?.every((pet) => pet.petType === petType_CAT)) {
      return cat_text;
    }
    if (currentQuote.petInfos?.every((pet) => pet.petType === petType_DOG)) {
      return dog_text;
    }
    return cat_and_dog_text;
  };

  return (
    <>
      <Grid alignLeft>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          <EditDetails>
            <StyledSectionHeading heading={section_heading} id={sectionHeadingId} />
            <EditSpan>
              <EditButtonLink
                aria-label={edit_button_screenreader_text}
                onClick={() => {
                  trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit assumptions');
                  navigate(assumptions.anchorLinkToFirstAssumption);
                }}>
                <EditButtonIcon name="edit" size="small" />
                {edit_button_text}
              </EditButtonLink>
            </EditSpan>
          </EditDetails>
          <DividerWithMargins />
          <StyledInfoPanel data-cy="aggs-assumptions-eligibility-panel">
            <div>
              {subheading && <Subheading>{subheading}</Subheading>}
              <AggregatorList>
                {assumptions.assumptions.map((assumption) => (
                  <AggregatorListItem key={generateKeyForElement(assumption)}>
                    {assumption.displayText}
                  </AggregatorListItem>
                ))}
              </AggregatorList>
              <RichTextWithModal
                html={petNameReplacer(combinedPetNames, getEligibilityInfoText())}
                pageTitle={PageTitle.CheckYourDetails}
              />
            </div>
            <DividerWithMargins />
            <AssumptionsQuestionField
              question={{ questionText: confirmation_text }}
              gridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
              errorText={getError('assumptionsAgreed')}>
              <BooleanRadioInput
                id="assumptionsAgreed"
                value={assumptionsAgreement.assumptionsAgreed}
                onChange={(value) => {
                  updateAssumptionsAgreement({ assumptionsAgreed: value });
                  showValidation('assumptionsAgreed');
                }}
              />
            </AssumptionsQuestionField>
          </StyledInfoPanel>
        </GridItem>
      </Grid>
    </>
  );
};

export default AggregatorAssumptionsSection;
