import React, { MouseEventHandler } from 'react';
import UnorderedListWithoutBullets from 'components/UnorderedListWithoutBullets';
import {
  DetailHeading,
  DetailWrapper,
  EditButtonIcon,
  EditButtonLink,
  EditSpan,
  HeadingSpan,
} from './styles';

type DetailGroupSectionProps = {
  sectionHeading: string;
  sectionId: string;
  details: (Detail | null)[];
  editButtonLabel: string;
  editButtonScreenreaderText: string;
  editButtonOnClick: MouseEventHandler;
  piiOptions?: PiiOptions;
};

export type Detail = {
  label: string;
  value: string;
  isPii: boolean;
  childBefore: React.ReactNode;
  childAfter: React.ReactNode;
};

export type PiiOptions = {
  headingIsPii?: boolean;
  detailLabelsArePii?: boolean;
  detailValuesArePii?: boolean;
};

const DetailGroupSection: React.FC<DetailGroupSectionProps> = ({
  details,
  editButtonOnClick,
  editButtonLabel,
  editButtonScreenreaderText,
  sectionHeading,
  sectionId,
  piiOptions,
}) => (
  <section aria-labelledby={sectionId}>
    <DetailWrapper>
      <HeadingSpan>
        {piiOptions?.headingIsPii ? (
          <DetailHeading id={sectionId} data-cs-mask={piiOptions?.headingIsPii}>
            {sectionHeading}
          </DetailHeading>
        ) : (
          <DetailHeading id={sectionId}>{sectionHeading}</DetailHeading>
        )}
      </HeadingSpan>
      <EditSpan>
        <EditButtonLink
          id={`${sectionId}-edit-button`}
          aria-label={editButtonScreenreaderText}
          onClick={editButtonOnClick}>
          <EditButtonIcon name="edit" size="small" />
          {editButtonLabel}
        </EditButtonLink>
      </EditSpan>
    </DetailWrapper>
    <UnorderedListWithoutBullets details={details} />
  </section>
);

export default DetailGroupSection;
