import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { petWillBeAgeNineAtCoverStartDate } from 'helpers/ageHelpers';
import { getReadablePercentageExcessAmount, PageTitle } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { voluntaryExcessPercentageReplacer } from 'helpers/placeholders/excessPlaceholders';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { CoPaymentTextContainer, StyledRichTextWithModalWidth } from './styles';
import { createDetail } from './useCreateDetail';

type CsExcessSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    excess: {
      fixed_excess: string;
      co_payment_contribution_pets_aged_under_9: string;
      co_payment_contribution_pets_aged_9_and_over: string;
      co_payment_contribution_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      excess {
        fixed_excess
        co_payment_contribution_pets_aged_under_9
        co_payment_contribution_pets_aged_9_and_over
        co_payment_contribution_text
      }
    }
  }
`;

const useExcessSection = (): Detail[][] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      excess: {
        fixed_excess,
        co_payment_contribution_pets_aged_under_9,
        co_payment_contribution_pets_aged_9_and_over,
        co_payment_contribution_text,
      },
    },
  } = useStaticQuery<CsExcessSectionProps>(query);
  const quote = useCurrentQuote();
  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  return petInfos.map((petInfo): Detail[] => [
    createDetail(
      petNameReplacer(petInfo.petName, fixed_excess),
      formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount),
      false
    ),
    createDetail(
      quote.policyInfo &&
        !petWillBeAgeNineAtCoverStartDate(petInfo.dob, quote.policyInfo.coverStartDate)
        ? co_payment_contribution_pets_aged_under_9
        : co_payment_contribution_pets_aged_9_and_over,
      getReadablePercentageExcessAmount(petInfo.voluntaryExcessPercentage),
      false,
      null,
      quote.policyInfo &&
        !petWillBeAgeNineAtCoverStartDate(
          petInfo.dob,
          quote.policyInfo.coverStartDate
        ) && (
          <CoPaymentTextContainer>
            <StyledRichTextWithModalWidth
              data-cy="copayment_text"
              html={petNameReplacer(
                petInfo.petName,
                voluntaryExcessPercentageReplacer(
                  getReadablePercentageExcessAmount(petInfo.voluntaryExcessPercentage),
                  co_payment_contribution_text
                )
              )}
              pageTitle={PageTitle.CheckYourDetails}
            />
          </CoPaymentTextContainer>
        )
    ),
  ]);
};

export default useExcessSection;
