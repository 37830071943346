import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { DocumentsSectionProps, PdfLink } from './DocumentsSection';

type DocumentSectionContent = {
  csPetPolicyDocumentsV2: {
    heading: string;
    product_information_document: PdfLink;
    essential_information: PdfLink;
    policy_booklet: PdfLink;
  };
};

const query = graphql`
  query {
    csPetPolicyDocumentsV2 {
      heading
      product_information_document {
        url
        text
      }
      essential_information {
        url
        text
      }
      policy_booklet {
        url
        text
      }
    }
  }
`;

export const useDocumentSectionProps = (): DocumentsSectionProps => {
  const {
    csPetPolicyDocumentsV2: {
      heading,
      product_information_document,
      essential_information,
      policy_booklet,
    },
  } = useStaticQuery<DocumentSectionContent>(query);

  const theme = useTheme();

  return {
    theme,
    heading,
    essentialInformation: essential_information,
    policyBooklet: policy_booklet,
    productInformationDocument: product_information_document,
  };
};
