import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import { PanelBodySpacing } from '@rsa-digital/evo-shared-components/components/Panel/LabelledPanel/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import Declaration from 'components/Declaration';
import { QuestionFieldWithMargin } from 'components/Declaration/styles';
import RichTextWithModal from 'components/RichTextWithModal';
import SectionHeading from 'forms/SectionHeading';

export const DeclarationSectionContainer = styled.div`
  padding-bottom: ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    padding-bottom: 0;
  `}

  ${mediaQuery.tabletLandscape`
    padding-bottom: ${spacing(4)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  padding: ${spacing(2)};
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)}
  `}
  border-bottom: solid 1px ${colors.neutral03};
`;

export const StyledPanel = styled.div`
  border: solid 1px ${colors.neutral03};
  margin-top: ${spacing(4)};
`;

export const StyledSectionHeading = styled(SectionHeading)`
  & {
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(1)};
    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(2)};
    `}
  }
`;

export const RichTextWithModalAndMarginBottom = styled(RichTextWithModal)`
  margin-bottom: 0;
  ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(1)};
    `}
`;

export const DeclarationWithBottomMargin = styled(Declaration)`
  background-color: ${colors.neutral05};
  border: none;
  padding: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
    padding: ${spacing(4)} ${spacing(4)} ${spacing(3)};
  `};

  > ${Grid} > ${GridItemWrapper} {
    border: none;
    padding: 0;
    margin-bottom: ${spacing(3)};

    ${mediaQuery.tabletPortrait`
        margin-bottom: ${spacing(4)};
    `};
  }

  && {
    p + p,
    p + ul,
    ul + p {
      margin-top: ${spacing(3)};
    }

    p + p.small {
      margin-top: ${spacing(2)};
    }
  }

  ${QuestionFieldWithMargin} {
    margin-bottom: 0;
    background-color: ${colors.neutral08};

    padding: ${({ error, theme }) =>
      error
        ? 0
        : `${spacing(2)({ theme })} ${spacing(3)({ theme })} ${spacing(3)({
            theme,
          })}`};

    ${mediaQuery.tabletPortrait`
      padding: ${({ error, theme }) =>
        error
          ? 0
          : `${spacing(3)({ theme })} ${spacing(4)({ theme })} ${spacing(4)({ theme })}`};
    `};

    border: ${({ error, theme }) =>
      error ? 'None ' : `1px solid ${colors.neutral02({ theme })}`};

    ${({ error, theme }) =>
      error
        ? `${PanelBodySpacing} {
            padding: 0 ${spacing(2)({ theme })};
            
            ${ContentSpacing} {
              width: 100%;
              margin-right: 0;
              margin-left: 0;  
            }   
          }`
        : ''};

    ${StyledRadioInput} {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};

      ${mediaQuery.tabletPortrait`
        flex-direction: row;
        gap: ${spacing(4)};
      `};

      ${RadioItem} {
        margin-left: 0;
      }
    }
  }
`;
