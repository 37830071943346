import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { petWillBeAgeNineDuringCover } from 'helpers/ageHelpers';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { SummaryOfNeedsSectionProps } from './SummaryOfNeedsSection';

type SummaryOfNeedsSectionContent = {
  csPetQuoteSummaryOfYourNeedsV2: {
    text_summary: {
      per_pet_excess_statement: string;
      pet_over_9_text: string;
      pet_under_9_text: string;
      text_before_statement: string;
      text_after_statement: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeedsV2 {
      text_summary {
        per_pet_excess_statement
        pet_over_9_text
        pet_under_9_text
        text_before_statement
        text_after_statement
      }
    }
  }
`;

export const useSummaryOfNeedsSectionProps = (): SummaryOfNeedsSectionProps => {
  const {
    csPetQuoteSummaryOfYourNeedsV2: {
      text_summary: {
        per_pet_excess_statement,
        pet_over_9_text,
        pet_under_9_text,
        text_after_statement,
        text_before_statement,
      },
    },
  } = useStaticQuery<SummaryOfNeedsSectionContent>(query);

  const quote = useCurrentQuote();
  const theme = useTheme();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const perPetStatements = quote.petInfos?.map((pet) => {
    const substituteExcessStatementPetPlaceholders = replacePlaceholdersRichText(
      petPlaceholders,
      pet
    );
    const coPaymentText =
      quote.policyInfo &&
      petWillBeAgeNineDuringCover(pet.dob, quote.policyInfo.coverStartDate)
        ? pet_over_9_text
        : pet_under_9_text;
    return {
      excessStatement: substituteExcessStatementPetPlaceholders(per_pet_excess_statement),
      coPaymentStatement: substituteExcessStatementPetPlaceholders(coPaymentText),
    };
  });

  return {
    theme,
    textBeforeStatement: substituteQuotePlacholders(text_before_statement),
    textAfterStatement: substituteQuotePlacholders(text_after_statement),
    perPetStatements,
  };
};
