import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { ProductId } from 'helpers/businessConstants';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { AssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { AggregatorSectionProps } from './AggregatorSection';
import { getAnswerText } from './useDeclarationSectionProps';

type AggregatorSectionContent = {
  csPetAggregatorsV2: {
    assumptions: {
      section_heading: string;
      subheading: string;
      confirmation_text: string;
    };
    eligibility_panel: {
      dog_text: string;
      cat_text: string;
      cat_and_dog_text: string;
    };
  };
  csPetGlobalConfig: {
    boolean_answer_text: {
      yes: string;
      no: string;
    };
  };
};

const query = graphql`
  query {
    csPetAggregatorsV2 {
      assumptions {
        section_heading
        subheading
        confirmation_text
      }
      eligibility_panel {
        dog_text
        cat_text
        cat_and_dog_text
      }
    }
    csPetGlobalConfig {
      boolean_answer_text {
        yes
        no
      }
    }
  }
`;

export const useAggregatorSectionProps = (
  assumptionsAgreement: AssumptionsAgreement,
  showAssumptions: boolean
): AggregatorSectionProps | undefined => {
  const {
    csPetAggregatorsV2: {
      assumptions: { section_heading, subheading, confirmation_text },
      eligibility_panel: { dog_text, cat_text, cat_and_dog_text },
    },
    csPetGlobalConfig: { boolean_answer_text },
  } = useStaticQuery<AggregatorSectionContent>(query);

  const theme = useTheme();
  const quote = useCurrentQuote();

  const assumptions = useAssumptions();

  const petNames = quote?.petInfos?.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const getEligibilityInfoText = (): string => {
    if (quote.petInfos?.every((pet) => pet.petType === petType_CAT)) {
      return cat_text;
    }
    if (quote.petInfos?.every((pet) => pet.petType === petType_DOG)) {
      return dog_text;
    }
    return cat_and_dog_text;
  };

  if (!showAssumptions || quote?.productId === ProductId.DIRECT) {
    return undefined;
  }

  return {
    theme,
    confirmationText: confirmation_text,
    subheading,
    sectionHeading: section_heading,
    assumptions,
    confirmationAnswer: getAnswerText(
      assumptionsAgreement.assumptionsAgreed,
      boolean_answer_text
    ),
    eligibilityInfoText: petNameReplacer(combinedPetNames, getEligibilityInfoText()),
  };
};
