import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, {
  spacingWithBorder,
} from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import { InfoPanel } from 'components/StatusPanel';
import SectionHeading from 'forms/SectionHeading';
import { Heading } from 'forms/SectionHeading/styles';

export const StyledSectionHeading = styled(SectionHeading)`
  margin: 0;
  max-width: 90%;
  ${GridItemWrapper} {
    margin: 0;

    ${Heading} {
      margin: 0;
    }
  }
`;

export const Subheading = styled.div`
  ${fonts.paragraphLarge}
  & {
    font-weight: bold;
  }
  margin-bottom: ${spacing(2)};
`;

export const EditDetailsText = styled(Subheading)`
  margin-top: ${spacing(1)};
  display: flex;
`;

export const AggregatorList = styled.ul`
  margin: 0 0 0 ${spacing(2)};
  & {
    padding-left: 0;
  }
`;

export const AggregatorListItem = styled.li`
  margin-bottom: ${spacing(2)};
`;

export const AssumptionsQuestionField = styled(QuestionField)`
  margin-bottom: ${spacingWithBorder(6, 1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacingWithBorder(2, 1)};
  `}
`;

export const DividerWithMargins = styled(Divider)`
  margin: ${spacing(3)} 0;
`;

export const StyledInfoPanel = styled(InfoPanel)`
  display: block;
  margin: ${spacing(4)} 0;

  && {
    padding: ${spacing(3)};
  }
  ${IconWrapper} {
    float: left;
    margin-right: ${spacing(2)};
  }
`;

export const EditDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: ${spacing(4)} 0;
`;
