import Image from '@rsa-digital/evo-shared-components/components/Image';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled, { css } from 'styled-components';

export const ImageWithFixedHeight = styled(Image)`
  height: ${spacing(4)};
  width: auto;

  margin: ${spacing(4)} 0 ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(2)} 0 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const ImageWrapper = styled.div`
  ${mediaQuery.tabletLandscape`
    display: flex;
    flex-direction: row;
  `}
`;

export const QuoteReferenceWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${spacing(6)};
  align-items: left;
`;

const quoteReferenceFonts = css`
  ${fonts.paragraph}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge}
  `}
`;

export const QuoteReferenceLabel = styled.span`
  ${quoteReferenceFonts}
`;

export const QuoteReferenceValue = styled.span`
  ${quoteReferenceFonts}

  & {
    font-weight: ${heavyFontWeight};
  }
`;
