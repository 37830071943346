import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import { WarningPanel } from 'components/StatusPanel';
import useAboutYouQuestions from 'forms/AboutYouForm/questions';
import { Heading } from 'forms/SectionHeading/styles';
import { trackFormTextFieldFocus } from 'helpers/eventTracking';
import { ContactDetails, useCustomerDetails } from 'state/formData/customerDetails';
import { ContactDetailsSectionContainer, StyledFlexibleWidthDivider } from './styles';

type YourContactDetailsSectionProps = {
  getError: FieldFunction<ContactDetails, string | undefined>;
  showValidation: FieldFunction<ContactDetails, void>;
  sectionId: string;
};

export type Detail = {
  label: string;
  value: string;
  childAfter?: React.ReactNode;
};

type CsYourContactDetails = {
  csPetCheckYourDetailsDetailsSectionV2: {
    your_contact_details: {
      heading: string;
      explanatory_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      your_contact_details {
        heading
        explanatory_text
      }
    }
  }
`;

const CheckContactDetailsForm: React.FC<YourContactDetailsSectionProps> = ({
  getError,
  showValidation,
  sectionId,
}) => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: { your_contact_details },
  } = useStaticQuery<CsYourContactDetails>(query);

  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();

  return (
    <ContactDetailsSectionContainer aria-labelledby={sectionId}>
      <Heading>{your_contact_details.heading}</Heading>
      <StyledFlexibleWidthDivider aria-hidden />
      <Grid alignLeft>
        <GridItem desktop={6.75} tabletLandscape={8.5} tabletPortrait={6}>
          <WarningPanel>
            <RichText html={your_contact_details.explanatory_text} />
          </WarningPanel>
        </GridItem>
        <GridItem>
          <QuestionField
            gridItemProps={{ desktop: 6.75, tabletLandscape: 8.5, tabletPortrait: 6 }}
            question={questions.customerEmail}
            errorText={getError('customerEmail')}>
            <TextInput
              id="customerEmail"
              placeholder={questions.customerEmail.placeholder}
              maxLength={254}
              value={customerDetails.customerEmail}
              onChange={(e) => {
                updateCustomerDetails({
                  customerEmail: e.target.value.replaceAll(' ', ''),
                });
              }}
              onBlur={() => showValidation('customerEmail')}
              onFocus={trackFormTextFieldFocus('Customer email')}
              inputMode="email"
            />
          </QuestionField>
        </GridItem>
        <GridItem>
          <QuestionField
            gridItemProps={{ desktop: 6.75, tabletLandscape: 8.5, tabletPortrait: 6 }}
            question={questions.customerTelephone}
            errorText={getError('customerTelephone')}>
            <NameInput
              id="customerTelephone"
              placeholder={questions.customerTelephone.placeholder}
              maxLength={11}
              value={customerDetails.customerTelephone}
              onChange={(e) => {
                updateCustomerDetails({
                  customerTelephone: e.target.value,
                });
              }}
              numbersOnly
              onBlur={() => showValidation('customerTelephone')}
              onFocus={trackFormTextFieldFocus('Customer telephone')}
              inputMode="tel"
            />
          </QuestionField>
        </GridItem>
      </Grid>
    </ContactDetailsSectionContainer>
  );
};
export default CheckContactDetailsForm;
