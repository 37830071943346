import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { graphql, useStaticQuery } from 'gatsby';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
} from 'helpers/referenceDataConstants';
import { CsOption } from 'types/contentStack';

type CsMongrelSize = {
  csPetAboutYourPetDogQuestionsV2: {
    mongrel_size: {
      options_with_icon: {
        small_size_option: CsOption;
        medium_size_option: CsOption;
        large_size_option: CsOption;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetDogQuestionsV2 {
      mongrel_size {
        options_with_icon {
          small_size_option {
            label
            description
            icon {
              icon_code
            }
          }
          medium_size_option {
            label
            description
            icon {
              icon_code
            }
          }
          large_size_option {
            label
            description
            icon {
              icon_code
            }
          }
        }
      }
    }
  }
`;

export const useMongrelSizeOptions = (): Option[] => {
  const { small_size_option, medium_size_option, large_size_option } = useStaticQuery<
    CsMongrelSize
  >(query).csPetAboutYourPetDogQuestionsV2.mongrel_size.options_with_icon;

  return [
    {
      value: mongrelSize_SMALL,
      name: small_size_option.label,
      icon: unwrapSingleton(small_size_option.icon)?.icon_code,
      description: small_size_option.description,
    },
    {
      value: mongrelSize_MEDIUM,
      name: medium_size_option.label,
      icon: unwrapSingleton(medium_size_option.icon)?.icon_code,
      description: medium_size_option.description,
    },
    {
      value: mongrelSize_LARGE,
      name: large_size_option.label,
      icon: unwrapSingleton(large_size_option.icon)?.icon_code,
      description: large_size_option.description,
    },
  ];
};
