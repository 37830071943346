import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsQuoteReferenceLabelProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    quote_reference_label: string;
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      quote_reference_label
    }
  }
`;

const useQuoteReference = (): Detail => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: { quote_reference_label },
  } = useStaticQuery<CsQuoteReferenceLabelProps>(query);
  const quote = useCurrentQuote();
  const quoteReference = quote.policyInfo?.quoteNumber ?? undefined;

  return createDetail(quote_reference_label, quoteReference, false);
};

export default useQuoteReference;
