import { Option } from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { useCatBreedTypeOptions } from 'forms/AboutYourPetsForm/useCatBreedTypeOptions';
import { useDogBreedTypeOptions } from 'forms/AboutYourPetsForm/useDogBreedTypeOptions';
import { useMongrelSizeOptions } from 'forms/AboutYourPetsForm/useMongrelSizeOptions';
import { getAgeShort } from 'helpers/ageHelpers';
import { ProductId } from 'helpers/businessConstants';
import { PageTitle } from 'helpers/eventTracking';
import { notKnownValueMasking } from 'helpers/notKnownHelper';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import {
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_PEDIGREE,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import { Detail } from './DetailGroupSection';
import {
  ChildAfterContainer,
  ChildBeforeContainer,
  StyledInfoPanel,
  StyledParagraphRichTextWithModal,
  StyledRichTextWithModal,
} from './styles';
import { createDetail } from './useCreateDetail';

type CsPetSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    about_your_pet: {
      age: string;
      amount_paid: string;
      chipped_or_tagged: string;
      gender: string;
      in_good_health: string;
      spayed_or_neutered: string;
    };
    about_your_pet_cat_specific_details: {
      breed: string;
      eligibility_conditions_agreement_cat: string;
    };
    about_your_pet_dog_specific_details: {
      breed: string;
      eligibility_conditions_agreement_dog: string;
    };
    eligibility_panel: {
      cat_text: string;
      dog_text: string;
    };
  };
  csPetAboutYourPetDogQuestionsV2: {
    pedigree_dog_breed_name: {
      breed_info_panel_text: string;
    };
    cross_breed_dog_breed_name: {
      breed_info_panel_text: string;
    };
    mongrel_size: {
      breed_info_panel_text: string;
    };
  };
  csPetAboutYourPetCatQuestionsV2: {
    cat_breed_name_pedigree: {
      breed_info_panel_text: string;
    };
    cat_breed_name_non_pedigree: {
      breed_info_panel_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      about_your_pet {
        age
        amount_paid
        chipped_or_tagged
        gender
        in_good_health
        spayed_or_neutered
      }
      about_your_pet_cat_specific_details {
        breed
        eligibility_conditions_agreement_cat
      }
      about_your_pet_dog_specific_details {
        breed
        eligibility_conditions_agreement_dog
      }
      eligibility_panel {
        cat_text
        dog_text
      }
    }
    csPetAboutYourPetDogQuestionsV2 {
      pedigree_dog_breed_name {
        breed_info_panel_text
      }
      cross_breed_dog_breed_name {
        breed_info_panel_text
      }
      mongrel_size {
        breed_info_panel_text
      }
    }
    csPetAboutYourPetCatQuestionsV2 {
      cat_breed_name_pedigree {
        breed_info_panel_text
      }
      cat_breed_name_non_pedigree {
        breed_info_panel_text
      }
    }
  }
`;

const generateSinglePetSection = (
  pet: QuoteResponsePetInfo,
  csPetSectionProps: CsPetSectionProps,
  breedTypes: Option[],
  sizeOptions: Option[],
  breeds: Option[],
  genders: Option[],
  isAggsQuote: boolean
): (Detail | null)[] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      about_your_pet: {
        age,
        amount_paid,
        chipped_or_tagged,
        gender,
        in_good_health,
        spayed_or_neutered,
      },
      about_your_pet_cat_specific_details,
      about_your_pet_dog_specific_details,
      eligibility_panel: { cat_text, dog_text },
    },
    csPetAboutYourPetDogQuestionsV2: {
      pedigree_dog_breed_name,
      cross_breed_dog_breed_name,
      mongrel_size,
    },
    csPetAboutYourPetCatQuestionsV2: {
      cat_breed_name_pedigree,
      cat_breed_name_non_pedigree,
    },
  } = csPetSectionProps;

  const { petName } = pet;
  const petGender = createDetail(
    petNameReplacer(petName, gender),
    genders.find((genderOption) => genderOption.value === pet.petGender)?.name,
    false
  );
  const petAge = createDetail(petNameReplacer(petName, age), getAgeShort(pet.dob), false);
  const formattedAmountPaid = `${formatInPoundsWithSign(pet.initialCostPound)}`;
  const amountPaid = createDetail(
    petNameReplacer(petName, amount_paid),
    formattedAmountPaid === '' ? 'Nothing paid' : formattedAmountPaid,
    false
  );
  const isPetSpayedValue = notKnownValueMasking(pet.isPetSpayed);
  const spayedOrNeutered = createDetail(
    petNameReplacer(petName, spayed_or_neutered),
    capitaliseFirstCharacter(isPetSpayedValue),
    false
  );
  const isPetChippedOrTaggedValue = notKnownValueMasking(pet.isPetChippedOrTagged);
  const chippedOrTagged = createDetail(
    petNameReplacer(petName, chipped_or_tagged),
    capitaliseFirstCharacter(isPetChippedOrTaggedValue),
    false
  );
  const inGoodHealth = createDetail(
    petNameReplacer(petName, in_good_health),
    pet.isPetShowingIllness ? 'No' : 'Yes',
    false
  );

  const getDogBreed = (): string => {
    return [mongrelSize_SMALL, mongrelSize_MEDIUM, mongrelSize_LARGE].includes(
      pet.petBreedType
    )
      ? `Mongrel or mixed breed, ${
          sizeOptions.find((sizeOption) => sizeOption.value === pet.petBreedType)?.name
        }`
      : `${breeds.find((breed) => breed.value === pet.petBreed)?.name}`;
  };

  const mongrelSizeReplacer = (mongrelSize: string, csString: string): string => {
    const replaceMongrelSize = (size: string): string =>
      csString.replace(/({{petMongrelSize}})/g, size);

    switch (mongrelSize) {
      case mongrelSize_SMALL:
        return replaceMongrelSize('small');
      case mongrelSize_MEDIUM:
        return replaceMongrelSize('medium');
      case mongrelSize_LARGE:
        return replaceMongrelSize('large');
      default:
        return '';
    }
  };

  const replaceBreed = (csString: string, breed: string): string =>
    csString.replace(/({{petBreedName}})/g, breed);

  const getDogBreedInfoText = (): string => {
    const dogBreed = getDogBreed();

    switch (pet.petBreedType) {
      case dogBreedType_PEDIGREE:
        return replaceBreed(pedigree_dog_breed_name.breed_info_panel_text, dogBreed);
      case dogBreedType_CROSS_BREED:
        return replaceBreed(cross_breed_dog_breed_name.breed_info_panel_text, dogBreed);
      case mongrelSize_SMALL:
        return mongrelSizeReplacer(
          mongrelSize_SMALL,
          replaceBreed(mongrel_size.breed_info_panel_text, dogBreed)
        );
      case mongrelSize_MEDIUM:
        return mongrelSizeReplacer(
          mongrelSize_MEDIUM,
          replaceBreed(mongrel_size.breed_info_panel_text, dogBreed)
        );
      case mongrelSize_LARGE:
        return mongrelSizeReplacer(
          mongrelSize_LARGE,
          replaceBreed(mongrel_size.breed_info_panel_text, dogBreed)
        );
      default:
        return '';
    }
  };

  const getCatBreed = (): string => {
    return `${breeds.find((breed) => breed.value === pet.petBreed)?.name}`;
  };

  const getCatBreedInfoText = (): string => {
    return pet.petBreedType === catBreedType_PEDIGREE
      ? replaceBreed(cat_breed_name_pedigree.breed_info_panel_text, getCatBreed())
      : replaceBreed(cat_breed_name_non_pedigree.breed_info_panel_text, getCatBreed());
  };

  const dogBreed = createDetail(
    petNameReplacer(petName, about_your_pet_dog_specific_details.breed),
    `${
      pet.petBreedType === dogBreedType_PEDIGREE ||
      pet.petBreedType === dogBreedType_CROSS_BREED
        ? breedTypes.find((breedType) => breedType.value === pet.petBreedType)?.name
        : ''
    } ${getDogBreed()} `,
    false,
    null,
    <ChildAfterContainer>
      <StyledInfoPanel data-cy="dog-breed-info-panel">
        <StyledParagraphRichTextWithModal
          html={petNameReplacer(petName, getDogBreedInfoText())}
          pageTitle={PageTitle.CheckYourDetails}
        />
      </StyledInfoPanel>
    </ChildAfterContainer>
  );

  const catBreed = createDetail(
    petNameReplacer(petName, about_your_pet_cat_specific_details.breed),
    `${
      breedTypes.find((breedType) => breedType.value === pet.petBreedType)?.name
    }, ${getCatBreed()}`,
    false,
    null,
    <ChildAfterContainer>
      <StyledInfoPanel data-cy="cat-breed-info-panel">
        <StyledParagraphRichTextWithModal
          html={petNameReplacer(petName, getCatBreedInfoText())}
          pageTitle={PageTitle.CheckYourDetails}
        />
      </StyledInfoPanel>
    </ChildAfterContainer>
  );

  // eligibility conditions agreement does not exist on pet, use below function
  const getEligibilityConditionsAgreement = (): boolean | undefined =>
    pet.isPetAtHomeAddress &&
    !(
      pet.petType === petType_DOG &&
      pet.legalActionInPreviousFiveYears &&
      pet.isComplaintMade
    );

  if (pet.petType === petType_CAT) {
    return [
      catBreed,
      isAggsQuote
        ? null
        : createDetail(
            petNameReplacer(
              petName,
              about_your_pet_cat_specific_details.eligibility_conditions_agreement_cat
            ),
            getEligibilityConditionsAgreement() ? 'Yes' : 'No',
            false,
            <ChildBeforeContainer>
              <StyledInfoPanel data-cy="eligibility-conditions-info-panel">
                <StyledRichTextWithModal
                  html={petNameReplacer(petName, cat_text)}
                  pageTitle={PageTitle.CheckYourDetails}
                />
              </StyledInfoPanel>
            </ChildBeforeContainer>
          ),
      inGoodHealth,
      petGender,
      petAge,
      amountPaid,
      spayedOrNeutered,
      chippedOrTagged,
    ];
  }

  return [
    dogBreed,
    isAggsQuote
      ? null
      : createDetail(
          petNameReplacer(
            petName,
            about_your_pet_dog_specific_details.eligibility_conditions_agreement_dog
          ),
          getEligibilityConditionsAgreement() ? 'Yes' : 'No',
          false,

          <ChildBeforeContainer>
            <StyledInfoPanel data-cy="eligibility-conditions-info-panel">
              <RichTextWithModal
                html={petNameReplacer(petName, dog_text)}
                pageTitle={PageTitle.CheckYourDetails}
              />
            </StyledInfoPanel>
          </ChildBeforeContainer>
        ),
    inGoodHealth,
    petGender,
    petAge,
    amountPaid,
    spayedOrNeutered,
  ];
};

const usePetSection = (): (Detail | null)[][] => {
  const csData = useStaticQuery<CsPetSectionProps>(query);
  const quote = useCurrentQuote();

  const gendersRefData = useReferenceData('genders')?.genders ?? [];
  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];
  const dogBreedTypes = useDogBreedTypeOptions();
  const catBreedTypes = useCatBreedTypeOptions();
  const dogSizeOptions = useMongrelSizeOptions();
  const isAggsQuote = quote.productId !== ProductId.DIRECT;

  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  return petInfos.map((petInfo): (Detail | null)[] =>
    generateSinglePetSection(
      petInfo,
      csData,
      petInfo.petType === petType_CAT ? catBreedTypes : dogBreedTypes,
      petInfo.petType === petType_CAT ? [] : dogSizeOptions,
      petInfo.petType === petType_CAT ? catBreedsRefData : dogBreedsRefData,
      gendersRefData,
      isAggsQuote
    )
  );
};

export default usePetSection;
