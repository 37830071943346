import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { PageTitle } from 'helpers/eventTracking';
import { appliesToSelection } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { CsDeclaration, Declaration } from 'helpers/useDeclarations';
import {
  DeclarationSectionContainer,
  DeclarationWithBottomMargin,
  RichTextWithModalAndMarginBottom,
  StyledGridItem,
  StyledPanel,
  StyledSectionHeading,
} from './styles';

type DeclarationSectionProps = {
  sectionHeadingId: string;
  declarations: Declaration[];
  updateHasAgreed: (update: boolean, index: number) => void;
  getError: FieldFunction<{ declarations: Declaration[] }, string | undefined>;
};

type CsDeclarationSection = {
  csPetCheckYourDetailsDeclaration: {
    section_heading: string;
    section_description: string;
    declaration: CsDeclaration[];
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetailsDeclaration {
      section_heading
      section_description
      declaration {
        applicable_products {
          product_selector
        }
        question_missed_error_text
        answered_no_error_text
      }
    }
  }
`;

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  sectionHeadingId,
  declarations,
  updateHasAgreed,
  getError,
}) => {
  const {
    csPetCheckYourDetailsDeclaration: {
      section_heading,
      section_description,
      declaration,
    },
  } = useStaticQuery<CsDeclarationSection>(query);
  const quote = useCurrentQuote();

  const getDeclarationErrorMessage = (
    errorType: string | undefined,
    index: number
  ): string => {
    const declarationContent = declaration.filter((singleDeclaration) =>
      appliesToSelection(quote.quoteOptions, singleDeclaration.applicable_products)
    );
    if (errorType === 'missing')
      return declarationContent[index]?.question_missed_error_text;
    if (errorType === 'invalid') return declarationContent[index]?.answered_no_error_text;
    return '';
  };

  return (
    <DeclarationSectionContainer>
      <StyledSectionHeading heading={section_heading} id={sectionHeadingId} />
      <Grid alignLeft>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          <RichTextWithModalAndMarginBottom
            pageTitle={PageTitle.CheckYourDetails}
            html={section_description}
          />
        </GridItem>
      </Grid>
      {declarations.map((_declaration, index) => (
        <StyledPanel key={_declaration.content.body_text}>
          <DeclarationWithBottomMargin
            id={`declaration[${index}]`}
            key={_declaration.content.body_text}
            hasAgreed={_declaration.hasAgreed}
            updateHasAgreed={(newValue) => updateHasAgreed(newValue, index)}
            error={getDeclarationErrorMessage(
              getError('declarations', ['hasAgreed', index]),
              index
            )}
            questionId={`declarations[${index}].hasAgreed`}
            questionText={_declaration.content.agreement_question}
            checkDetailDeclarationStyle>
            <Grid alignLeft>
              <StyledGridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
                <RichTextWithModal
                  pageTitle={PageTitle.CheckYourDetails}
                  html={_declaration.content.body_text}
                />
              </StyledGridItem>
            </Grid>
          </DeclarationWithBottomMargin>
        </StyledPanel>
      ))}
    </DeclarationSectionContainer>
  );
};

export default DeclarationSection;
