import { graphql, useStaticQuery } from 'gatsby';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import {
  getProductFromProductType,
  getVetFeeLimitFromProduct,
} from 'helpers/productHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsCoverLevelSectionProps = {
  csPetCheckYourDetailsDetailsSectionV2: {
    your_cover_level: {
      vet_fees_limit: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSectionV2 {
      your_cover_level {
        vet_fees_limit
      }
    }
  }
`;

const useCoverLevelSection = (quote?: CurrentQuote | undefined): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSectionV2: {
      your_cover_level: { vet_fees_limit },
    },
  } = useStaticQuery<CsCoverLevelSectionProps>(query);
  if (quote === undefined) {
    return [];
  }

  const product = getProductFromProductType(quote.quoteOptions.productType);

  const getVetFeeLimitText = (): string => {
    const vetFeeLimit = getVetFeeLimitFromProduct(quote.quoteOptions.productType);
    const formattedVetFeeLimit = formatInPoundsWithSign(vetFeeLimit);

    return product ? `Up to ${formattedVetFeeLimit} for each pet, per year` : '';
  };

  return [createDetail(vet_fees_limit, getVetFeeLimitText(), false)];
};

export default useCoverLevelSection;
